import { createSlice } from "@reduxjs/toolkit";

export const maticBalanceSlice = createSlice({
    initialState: 0,
    name: "maticaBalance",
    reducers: {
        updateMaticBalance: (state, action) => action.payload,
    },
})

export const { updateMaticBalance } = maticBalanceSlice.actions;
export default maticBalanceSlice.reducer;