import { createSlice } from '@reduxjs/toolkit';

const itemName = `haiku-models`;

function getModelsList(){


    const modelData = sessionStorage.getItem(itemName);
    if(modelData){
        return JSON.parse(modelData);
    }else{
        sessionStorage.setItem(itemName,JSON.stringify([]))
        return []
    }
}

function setModels(models:any[]){

    if(models){
        sessionStorage.setItem(itemName,JSON.stringify(models))
    }

}

const initialState = getModelsList()

export const models = createSlice({
    name:`Models`,
    initialState,
    reducers:{
        addModels:(state,action)=> action.payload,
    }
})

export const {addModels} = models.actions;
export default models.reducer;