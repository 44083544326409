import { useState } from "react";
// import Image from "../Image";
import { useNavigate } from "react-router-dom";

export function Modal(props: modalProps) {
  const [showModal, setShowModal] = useState(true);
  const navigate = useNavigate();

  const rightBtnHandler = () => {
    props.btnRightAction && props.btnRightAction();
    setShowModal(false);
  };

  const leftBtnHandler = () => {
    props.btnLeftAction && props.btnLeftAction();
    setShowModal(false);
  };

  const onClose = () => {
    setShowModal(false);
    navigate("/");
  };
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-1/3 my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-center p-3 px-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold text-black">
                    {props.title}
                  </h3>
                  <button
                    className="ml-auto text-center p-2 text-red-600 opacity-50 hover:opacity-80 text-3xl font-semibold ease-linear transition-all duration-150"
                    onClick={onClose}
                  >
                    <span className="text-3xl block focus:outline-none">X</span>
                  </button>
                </div>
                {/* <div className="p-6 flex justify-center">
                  {props.icon && (
                    <Image src={`./icons/${props.icon}.svg`} style={"w-20"} />
                  )}
                </div> */}
                {props.body && (
                  <div className="relative font-bold p-6 flex-auto text-center">
                    <p className={`text-indigo-800 text-lg leading-relaxed`}>
                      {props.body}
                    </p>
                  </div>
                )}
                {/* <div className="flex items-center gap-4 justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  {props.btnLeft && (
                    <button
                      className="text-red-500 hover:text-red-700 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={leftBtnHandler}
                    >
                      {props.btnLeft}
                    </button>
                  )}
                  <button
                    className="bg-blue-500 text-white active:bg-blue-900 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={rightBtnHandler}
                  >
                    {props.btnRight}
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="opacity-60 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default Modal;
