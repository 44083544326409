

import React, { ChangeEvent, useState } from 'react'
import { Link } from 'react-router-dom';
import Image from "../Image";
import { IoIosCloseCircleOutline } from 'react-icons/io';
import Button from '../buttons';
import { postRequest } from '../../api/axios';
import { ToastContainer, toast } from 'react-toastify';


export const AddTogetheraiModels = () => {

// form has these fields => title,modelName aka endpoint,organization,logo,category
    const [formData, setFormData] = useState<any>({isActive: true});
    const [logo,setLogo] = useState(``);



    const handleFormDataChange = (e:any) =>{

        setFormData((prev:any) => ({...prev,[e.target.name]:e.target.value}))

    }


    const handleLogo = (e: ChangeEvent<any>) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          const result = reader.result;
          setLogo(`${result}`);
        };
        reader.readAsDataURL(file);
      };


    const handleSubmitModel = async(e: any) => {
        e.preventDefault();
        console.log(formData);

        try{
            await toast.promise(
                () =>
                  postRequest(`/api/newModel/togetherai`, {
                    modelData: { ...formData, logo: logo },
                  }),
                {
                  pending: `Submitting...`,
                  error: `Some Error Occured`,
                  success: `Model Submitted Successfully`,
                }
              );
        }catch(err){
            console.error(err)
        }
    }


    return (<><div className='w-full overflow-x-hidden '>
        <Link className=" block text-blue-600" to={`#`}>
            <Image src="/image/logo.png" style={`w-[10%]`} />
        </Link>

        <h1 className=" text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
            Add Models to Decentr.ai
        </h1>

        <form
            onSubmit={handleSubmitModel}
            className="mt-6 grid grid-cols-6 gap-4 "
        >
            {/* <HorizontalLine text={`Model Info`} /> */}

            <div className="col-span-3 sm:col-span-3">
                <label
                    htmlFor="title"
                    className="block text-sm font-medium text-gray-700 text-lg"
                >
                    Model Title
                </label>

                <input
                    onChange={handleFormDataChange}
                    value={formData.title ?? ``}
                    required
                    type="text"
                    id="title"
                    name="title"
                    placeholder="Title"
                    className="mt-1 w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                />
            </div>
            <div className="col-span-3 sm:col-span-3">
                <label
                    htmlFor="organization"
                    className="block text-sm font-medium text-gray-700 text-lg"
                >
                    Organization
                </label>

                <input
                    onChange={handleFormDataChange}
                    value={formData.organization ?? ``}
                    required
                    type="text"
                    id="organization"
                    name="organization"
                    placeholder="Organization Name"
                    className="mt-1 w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                />
            </div>
            <div className="col-span-6 ">
                <label
                    htmlFor="logo"
                    className="mb-1 block text-sm font-medium text-gray-700 text-lg"
                >
                    Logo{" "}
                </label>
                {logo.length > 0 ? (
                    <div className="relative max-w-[200px] max-h-[200px] ">
                        <IoIosCloseCircleOutline
                            onClick={() => setLogo(``)}
                            className="absolute text-[30px] top-[0] translate-y-[-50%] left-[100px] translate-x-[-50%]  text-red-500 cursor-pointer"
                        />
                        <img
                            className="w-[100px] h-[100px] rounded-xl"
                            src={`${logo}`}
                            alt="Model Logo"
                        />
                    </div>
                ) : (
                    <input
                        onChange={(e) => handleLogo(e)}
                        type="file"
                        id="logo"
                        name="logo"
                    />
                )}
            </div>



            <div className="col-span-6 sm:col-span-3">
                <label
                    htmlFor="modelName"
                    className="block text-sm font-medium text-gray-700 text-lg"
                >
                    Model EndPoint
                </label>

                <input
                    onChange={handleFormDataChange}
                    value={formData.modelName ?? ``}
                    required
                    type="text"
                    id="modelName"
                    name="modelName"
                    placeholder="Model Name ie: meta-llama/Llama-3-8b-chat-hf"
                    className="mt-1 w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                />
            </div>

            <div className="col-span-6 sm:col-span-3">
                <label
                    htmlFor="HeadlineAct"
                    className="block text-sm font-medium text-gray-900 text-lg"
                >
                    {" "}
                    Category{" "}
                </label>
                <select
                    onChange={handleFormDataChange}
                    value={formData.category ?? ``}
                    required
                    name="category"
                    id="category"
                    className="mt-1 border-2 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm p-2 text-lg "
                >
                    <option value="">Model Type</option>
                    <option value="chat">Chat</option>
                    <option value="code">code</option>
                    {/* <option value="text_to_image">Text to Image</option>
                    <option value="image_to_text">Image to Text</option>
                    <option value="text_to_speech">Text to Audio</option> */}
                </select>
            </div>

            <div className="col-span-6">
                <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700 text-lg"
                >
                    {" "}
                    Description{" "}
                </label>

                <textarea
                    rows={3}
                    onChange={handleFormDataChange}
                    value={formData.description ?? ``}
                    id="description"
                    name="description"
                    placeholder="Description about model"
                    maxLength={250}
                    className="mt-1 w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                />
            </div>
            <div className='col-span-6 flex items-center justify-center'>
                
            <Button design={'secondary'}><input className='cursor-pointer' type='submit' value={`submit`} /></Button>
            </div>

        </form>

    </div>
    <ToastContainer/>
    </>
    )
}


const HorizontalLine: React.FC<any> = (props) => {
    return (
        <span className="flex items-center col-span-6  p-2">
            <span className="h-px flex-1 bg-black"></span>
            <span className="shrink-0 px-6">{props.text}</span>
            <span className="h-px flex-1 bg-black"></span>
        </span>
    );
};