import React, { useEffect, useState } from "react";

// import ModalComponent from "../ModalComponent";
// import Modal from "../Modal";
import Modal from "../model";

import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { googleLogout } from "@react-oauth/google";
import { setSigninType, setUserProfile } from "../../Redux/Slices/userProfile";
import { useWeb3Modal } from "@web3modal/scaffold-react";
import { updateAddress } from "../../Redux/Slices/walletAddress";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import LoginModalButtons from "../loginModalButtons";
import Web3Auth from "../../services/Web3Auth";
import { Web3AuthMPCCoreKit } from "@web3auth/mpc-core-kit";

function Signin() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalAuth, setModalAuth] = useState<boolean>(false);
  const { open } = useWeb3Modal();
  const { name } = useAppSelector((state) => state.userProfile.userInfo);
  const { singinType } = useAppSelector((state) => state.userProfile);
  const walletAddress = useAppSelector((state) => state?.walletAddress);

  const { address, isConnected } = useWeb3ModalAccount();
  const dispatch = useAppDispatch();

  let updatedName;
  if (name !== "") {
    const arrayName = name.split(" ");
    updatedName = arrayName[0];
  }

  const logOut = () => {
    googleLogout();
    dispatch(setUserProfile({ name: "", email: "" }));
  };

  const handleClick = () => {
    if (name === "" && walletAddress === null) {
      setModalOpen(true);
      setModalAuth(false);
    } else {
      if (name !== "") {
        logOut();
      } else if (walletAddress !== null && singinType === "Web3Auth") {
        setModalAuth(true);
        setModalOpen(true);
      } else {
        // setModalAuth(false);
        open();
        if (!isConnected) {
          dispatch(updateAddress(null));
          dispatch(setSigninType(""));
        }
      }
    }
  };

  useEffect(() => {
    if (name !== "" || walletAddress !== "") {
      setModalOpen(false);
    }
    if ((walletAddress === null || address === undefined) && name === "") {
      dispatch(updateAddress(null));
      dispatch(setSigninType(""));
    } else if (isConnected) {
      console.log("here");
      dispatch(updateAddress(address));
      dispatch(setSigninType("wallet"));
    }
  }, [name, isConnected, address]);
  return (
    <>
      <span
        // className='font-bold text-sm bg-gradient-to-br from-[#2BB2FF] to-[#0BD6CA] bg-clip-text border-[1px] border-[#2BB2FF] py-2 px-4 rounded-3xl text-transparent bg-[#ffffffd4] cursor-pointer'
        onClick={handleClick}
      >
        {singinType === ""
          ? "Sign in"
          : singinType !== "" &&
            (updatedName ||
              //@ts-ignore
              walletAddress?.substring(0, 4) +
                "..." +
                //@ts-ignore
                walletAddress?.substring(walletAddress?.length - 4))}
      </span>
      {/* {modalOpen && (
        <></>
        // <Modal
        //   title="Sign In"
        //   btnRight="he"
        //   bodyColor="red"
        //   body={<ModalComponent />}
        //   icon=""
        //   btnRightAction={() => {
        //     console.log("hello");
        //   }}
        //   btnLeft="0"
        //   btnLeftAction={() => {
        //     console.log("hello");
        //   }}
        // />
      )} */}

      <Modal
        isModal={modalOpen}
        closeModal={() => {
          setModalOpen(false);
        }}
      >
        {modalAuth ? <Web3Auth /> : <LoginModalButtons />}
      </Modal>
    </>
  );
}

export default Signin;
