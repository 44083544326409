const CardSkelton: any = () => {

    return <div 
    className="bg-white w-full min-w-[275px] flex-grow flex-1 rounded-3xl shadow-card-shadow hover:shadow-card-shadow-hover transition-all duration-[0.3s] "
    >
        <div className="w-full bg-[#CFCCF1] animate-shimmer h-[155px] rounded-t-3xl flex flex-col items-center justify-center py-6 px-10 bg-cover cardPatternImage" >

            {/* <Image src={logo ?? `/image/notFound.PNG`} style={`max-w-[100px] flex  bg-contain rounded-3xl shadow-model-shadow`} /> */}
            <div className=' bg-shine animate-shimmer p-11 rounded-3xl'></div>
        </div>
        <div className="p-4 flex flex-col">
            <div className="flex flex-col">
                <h6 className="font-bold text-md bg-shine animate-shimmer max-w-[80px] text-transparent">----</h6>

                <span className='flex gap-2 text-xs'><p className="font-medium text-xs text-[#8B8D98] mt-1 bg-shine animate-shimmer min-w-[50px] text-transparent">----</p></span>
            </div>
            <p className="font-medium text-sm text-[#8B8D98] my-4 bg-shine animate-shimmer text-transparent">This is a very brief, very human, description of what this model does</p>
            <div className="w-full flex justify-start items-center gap-2 mb-4">
                <span className="py-1 px-3 font-bold text-xs rounded-full bg-[#F3F5F6] bg-shine animate-shimmer text-transparent">Productivity</span>
                <span className="py-1 px-3 font-bold text-xs rounded-full bg-[#F3F5F6] bg-shine animate-shimmer min-w-[50px] text-transparent">chat</span>
            </div>
        </div>
    </div>


}

export default CardSkelton