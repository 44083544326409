import { useEffect } from "react";
import { updateAddress } from "../../Redux/Slices/walletAddress";
import { updateMaticBalance } from "../../Redux/Slices/maticBalance";
import { setSigninType } from "../../Redux/Slices/userProfile";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import Image from "../../components/Image";
import { ethers } from "ethers";
// import Modal from "../../components/Modal";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalSigner,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers5/react";
import ToastMessage from "../../components/ToastMessage";
// import { deliverMaticTokens } from "../../api";
// import { setSigninType } from "../../Redux/Slices/userProfile";

const projectId = process.env.REACT_APP_wallet_con_id || "";

const Networks = [
  {
    chainId: 42100,
    name: "DecentrAI Testnet",
    currency: "TDETER",
    rpcUrl: "https://dev.decentr.ai/rpc",
    explorerUrl: "",
  },
];

const defaultChain = {
  chainId: 42100,
  name: "DecentrAI Testnet",
  currency: "TDETER",
  rpcUrl: "https://dev.decentr.ai/rpc",
  explorerUrl: "",
};

const metadata = {
  name: "Decentr.AI",
  description: "My Website description",
  url: "http://localhost:3000/",
  icons: ["https://avatars.mywebsite.com/"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: Networks,
  defaultChain,
  projectId,
});

function WalletConnect(props: any) {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const { signer } = useWeb3ModalSigner();

  const dispatch = useAppDispatch();
  // const hideText = !props.active;
  // const [showToast, setShowToast] = useState(false);
  // const [error, setError] = useState(false);
  // const [toastMsg, setToastMsg] = useState("Wallet Connected");

  const getBalance = async (address: string) => {
    try {
      if (address) {
        //@ts-ignore
        const balance = await signer.provider.getBalance(address);
        const balanceFormated = ethers.utils.formatEther(balance);
        dispatch(updateAddress(address));
        dispatch(setSigninType("walletConnect"));
        dispatch(updateMaticBalance(balanceFormated));
      }
    } catch (error) {
      console.log("Error updating matic balance");
    }
  };

  useEffect(() => {
    try {
      const addr: string | undefined = address?.toString();
      if (addr !== null && isConnected) {
        //@ts-ignore
        getBalance(addr);
      }
    } catch (e) {
      console.log(e);
    }
  }, [address]);

  return (
    <div>
      <button
        onClick={() => open()}
        className="bg-[#141718] w-30 hover:bg-[#77767b] text-white text-sm py-2 px-6 border-[#77767b] rounded-xl border-2"
      >
        <div className="flex justify-center items-center gap-2 font-bold">
          <div style={{ minWidth: "30px" }}>
            <Image src={`/image/walletconnect.svg`} style={`w-[40px]`} />
          </div>
          <span style={{ display: "block" }}>
            {!address ? "Connect" : "Disconnect"} Wallet
          </span>
        </div>
      </button>
      {isConnected && (
        <ToastMessage message={`Wallet Connected`} error={false} />
      )}
    </div>
  );
}

export default WalletConnect;
