import axios from "axios";

const base_url = process.env.REACT_APP_BACKEND_URL;
// const base_url = "https://api.decentr.ai"

/**
 * Function For get Request
 * @param endpoint i.e 'faucet/'
 * @param params json object containing headers
 * @returns api response object
 */
const getRequest = async (
  endpoint: string,
  params?: {
    [key: string]: string;
  }
) => {
  let url: string = base_url + endpoint;
  try {
    if (params) {
      url = url + "?";
      Object.keys(params).forEach((key: string) => {
        url = url + key + "=" + params[key] + "&";
      });
      url = url.slice(0, -1);
    }

    const response = await axios.get(url);
    return response ? response.data : undefined;
  } catch (err) {
    console.log("Error ", url);
    return undefined;
  }
};

/**
 * Function for post requests
 * @param endpoint i.e 'faucet/'
 * @param body api json body
 * @returns api response object
 */
const postRequest = async (
  endpoint: string,
  body?: {
    [key: string]: any;
  }
) => {
  let url: string = base_url + endpoint;
  try {
    console.log(body)
    const response = await axios.post(url, body ? body : {});
    return response ? response.data : undefined;
  } catch (err) {
    console.log("Error ", url);
    return undefined;
  }
};

export { getRequest, postRequest };
