import { Link } from "react-router-dom"
import Image from '../Image'

interface CardProps {
    model: any
}

 const Card: React.FC<CardProps> = ({ model }) => {
    const { title, logo, organization, description,category } = model

    return <Link to={`/chatbot?model=${model?.modelName}`}
    className="bg-white w-full min-w-[275px] flex-grow flex-1 rounded-3xl shadow-card-shadow hover:shadow-card-shadow-hover transition-all duration-[0.3s] "
    >

            <div className="w-full bg-[#CFCCF1] h-[155px] rounded-t-3xl flex flex-col items-center justify-center py-6 px-10 bg-cover cardPatternImage" >

                <Image src={logo ?? `/image/notFound.PNG`} style={`w-[88px] h-[88px] flex  bg-contain rounded-xl shadow-model-shadow `} />

            </div>
            <div className="p-4 flex flex-col items-stretch">
                <div className="flex flex-col">
                    <h6 className="font-bold text-md">{title}</h6>
                    <p className="font-medium text-xs text-[#8B8D98]">by <span className="capitalize">{organization}</span></p>
                </div>
                <p className="font-medium text-sm text-[#8B8D98] my-4 h-10 text-ellipsis line-clamp-2">{description}</p>
                <div className="w-full flex justify-start items-center gap-2 ">
                    <span className="py-1.5 px-3 font-bold text-xs rounded-md bg-[#F3F5F6] hover:bg-[#E7E7E7] active:bg-[#2BB2FF] active:text-white transition-all duration-300">Productivity</span>
                    <span className="py-1.5 px-3 font-bold text-xs rounded-md bg-[#F3F5F6] hover:bg-[#E7E7E7] active:bg-[#2BB2FF] active:text-white transition-all duration-300 capitalize">{category}</span>
                </div>
            </div>
    </Link>

}


export default Card;