import React, { useEffect, useState } from 'react';

import Button from '../../components/buttons';
import { useAppSelector } from '../../Redux/hooks';
import Card from '../../components/cards/modelCard';
import HeroSection from '../../components/heroSections/main';
import CardSkelton from '../../components/cards/modelCardSkelton';


const AllModels = () => {

    const modelsList = useAppSelector((state) => state.models)

    const [allActiveModels, setAllActiveModels] = useState([]);
    const [skeltonCount,setSkeltonCount] = useState(8)


    useEffect(() => {

        if (modelsList && modelsList.length > 0) {
            setAllActiveModels(modelsList);
        }

    }, [modelsList.length]);

    return <>
        <div className='w-full' >
            <HeroSection />
            <div id='models' className="max-w-[1320px] ml-auto mr-auto">
                <div className="flex justify-between items-center px-10 mb-3">
                    <h4 className="font-bold text-lg">All models</h4>
                    <Button design={'link'}>All Categories</Button>
                    {/* <p className="font-bold text-base text-[#2BB2FF]">All Categories</p> */}
                </div>
                <div id='AllModels' className="flex flex-wrap items-start justify-beetween gap-6 px-8">
                    {allActiveModels && allActiveModels.length > 0 && allActiveModels.map((model, index) => {
                        return <Card
                            key={index}
                            model={model}
                        />
                    })}

                    {allActiveModels?.length <= 0 && [...Array(skeltonCount)].map((key, index) => {
                        return <CardSkelton key={index} />
                    })}
                </div>
            </div>
        </div>
    </>

}

export default AllModels;

