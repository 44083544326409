import React from "react";
import { SiRobotframework } from "react-icons/si";
import { displayValueForKey, messageResponse } from "../functions";


const ChatbotResponse: React.FC<any> = (props) => {


  return (
    <>
      {props.response.user && (
        <div className="user msg w-full flex items-center justify-end ">
          <span className={`px-2 p-1 m-1 bg-[#0e25291b] rounded-lg text-gray-[300] font-md text-md flex flex-col items-start justify-end`}>
            {
              props.modelInfo.inputType.map((input: any) => messageResponse(input.type, displayValueForKey(props.response.user, input.name)))

            }
          </span>
        </div>
      )}

      {props.response.server && (
        <div className="ai-msg w-full flex items-center justify-start ">
          {props.modelInfo.logo ? <img
            src={props.modelInfo.logo}
            className="max-w-[25px] max-h-[25px] min-w-[25px] min-h-[25px]"
            alt=""
          /> :
            <SiRobotframework className="text-sm text-[#3BB5FE]" />
          }
          <span className="px-4 m-5 bg-gray-100 rounded-lg text-gray-700 font-md text-md ">

            {props.response.server === `Server Error` ?

              <div role="alert" className="rounded border-s-4 border-red-500 bg-red-50 p-4">
                <strong className="block font-medium text-red-800"> Something went wrong</strong>
              </div>

              : <>{
                props.modelInfo.output.map((output: any) => messageResponse(output.type, displayValueForKey(props.response.server, output.key)))
              }</>

            }

          </span>
        </div>
      )}
    </>
  );
};

export default ChatbotResponse;
