import { Link } from 'react-router-dom';
import Image from '../Image'
// import CardForm from './modelCardForm';
import { ChangeEvent, useState } from 'react';
// import { IoSettingsOutline, IoSettingsSharp } from 'react-icons/io5';
import Button from '../buttons';
import { toast,ToastContainer } from 'react-toastify';
import { postRequest } from '../../api/axios';
import { Formik, Form, Field, FieldArray, ErrorMessage, FastField } from 'formik'
import { FcAddImage } from 'react-icons/fc';
import * as Yup from 'yup';
import { IoIosCloseCircleOutline } from 'react-icons/io';
// import { object, string, number, date, InferType } from 'yup';

const inputTypeSchema = Yup.object().shape({
    type: Yup.string().required(`required`),
    name: Yup.string().required(`required`),
    isRequired: Yup.boolean(),
});

const outputSchema = Yup.object().shape({
    type: Yup.string().required(`required`),
    key: Yup.string().required(`required`),
});

const parametersSchema = Yup.object().shape({
    name: Yup.string().required(`required`),
    type: Yup.string().required(`required`),
    current: Yup.string().required(`required`),
    min: Yup.number()
        .nullable()
        .positive('must be +ve')
        .when('type', {
            is: 'range',
            then: (schema) => schema.required('Min is required'),
        }),
    max: Yup.number()
        .nullable()
        .positive('must be +ve')
        .when('type', {
            is: 'range',
            then: (schema) =>
                schema.required('Max is required').test({
                    name: 'max-greater-than-min',
                    exclusive: false,
                    message: 'Max must be greater than or equal to Min',
                    test: function (max) {
                        const { min } = this.parent;
                        return max === undefined || min === undefined || max >= min; // dont fail test if max or min is undefined
                    },
                }),
        }),
    step: Yup.number()
        .nullable()
        .positive('must be +ve')
        .when('type', {
            is: 'range',
            then: (schema) =>
                schema.required('Step is required').test({
                    name: 'step-should-be-less-than-max-min',
                    exclusive: false,
                    message: 'Step value should be less',
                    test: function (step) {
                        const { min, max } = this.parent;
                        if (min !== undefined && max !== undefined) {
                            const diff = (max - min) / 2;
                            return step <= diff;
                        }
                        return true; // If min or max is undefined, don't fail the test
                    },
                }),
        }),
    disable: Yup.boolean(),
});

const validationSchema = Yup.object().shape({
    title: Yup.string().required(`required`),
    modelName: Yup.string().required(`required`),
    organization: Yup.string().required(`required`),
    category: Yup.string().required(`required`),
    description: Yup.string().required(`required`),
    routeUrl: Yup.string().required(`required`),
    logo: Yup.string().required(`required`),
    inputType: Yup.array().of(inputTypeSchema),
    output: Yup.array().of(outputSchema),
    parameters: Yup.array().of(parametersSchema)
});

const AddCustomModel = () => {

    const [modelLogo, setLogo] = useState(``);
    const handleLogo = (e: ChangeEvent<any>) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const result = reader.result;
            setLogo(`${result}`);
        };
        reader.readAsDataURL(file);
    };


    const convertToBase64 = async (file: any) => {
        return new Promise((resolve, reject) => {

            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => { resolve(fileReader.result); };
            fileReader.onerror = (error) => { reject(error); };

        });
    }




    return <>
        <div className="w-full">
            <ToastContainer />
            <Link className=" block text-blue-600" to={`#`}>
                <Image src="/image/logo.png" style={`w-[10%]`} />
            </Link>

            <h1 className=" text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
                Add Custom Models to Decentr.ai
            </h1>

            <Formik
                initialValues={{
                    title: ``,
                    modelName: ``,
                    organization: ``,
                    category: ``,
                    description: ``,
                    routeUrl: ``,
                    logo: null,
                    inputType: [],
                    output: [],
                    parameters: []

                }}
                validationSchema={validationSchema}
                onSubmit={ async (values) => {
                    const {logo,...formValues} = values;

                    try{
                        await toast.promise(
                            () =>
                              postRequest(`/api/newModel`, {
                                modelData: {...formValues,logo:modelLogo,isActive:true,createdBy:`testing`},
                              }),
                            {
                              pending: `Submitting...`,
                              error: `Some Error Occured`,
                              success: `Model Submitted Successfully`,
                            }
                          );
                    }catch(err){
                        console.error(err)
                    }
                }
                }
            >

                {(formik) => <Form className='w-full flex justify-around items-start gap-5  flew-wrap flex-col sm:flex-row'>
                    <div className='flex-1 xl:flex-[2] min-w-[300px] p-3 w-full'>

                        <div
                            className="bg-white w-full border-2 min-w-[275px] flex-grow flex-1 rounded-3xl shadow-card-shadow hover:shadow-card-shadow-hover transition-all duration-[0.3s] "
                        >

                            <div className="w-full bg-[#CFCCF1] h-[155px] rounded-t-3xl flex flex-col items-center justify-center py-6 px-10 bg-cover cardPatternImage" >

                                {modelLogo ? <span className='relative '>
                                    <IoIosCloseCircleOutline
                                        onClick={() => setLogo(``)}
                                        className="absolute text-[30px] top-[0] translate-y-[-50%] left-[90px] translate-x-[-50%]  text-red-500 cursor-pointer"
                                    />
                                    <Image src={modelLogo ?? `/image/notFound.PNG`} style={`w-[88px] h-[88px] flex  bg-contain rounded-xl shadow-model-shadow `} />
                                </span>
                                    : <div className={`cursor-pointer relative w-[88px] h-[88px] flex bg-contain rounded-xl shadow-model-shadow`}>

                                        <input
                                            type="file"
                                            name="logo"
                                            className="cursor-pointer opacity-0 relative z-[2]"
                                            accept="image/*"
                                            onChange={(e: any) => {
                                                if (e.currentTarget.files) {
                                                    formik.setFieldValue(`logo`, e.currentTarget.files[0]);
                                                    handleLogo(e);
                                                }
                                            }}
                                        />
                                        <FcAddImage className={`w-full h-full p-4 absolute z-[1]`} />
                                    </div>
                                }

                            </div>
                            <div className="p-4 flex flex-col items-stretch">
                                <div className="flex flex-col">
                                    <div className="">

                                        <FastField
                                            type="text"
                                            id="title"
                                            name="title"
                                            placeholder="Title"
                                            className=" w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                                        />
                                        <Error name='title' />
                                    </div>
                                    <div className="mt-1 ">
                                        <FastField
                                            type="text"
                                            id="modelName"
                                            name="modelName"
                                            placeholder="Model Name"
                                            className=" w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                                        />
                                        <Error name='modelName' />
                                    </div>
                                    <div className=" mt-1 font-medium text-xs text-[#8B8D98] flex flex-wrap items-center justify-center">by
                                        <FastField
                                            type="text"
                                            id="organization"
                                            name="organization"
                                            placeholder="Organization"
                                            className="ml-1 w-[95%] rounded-md bg-white text-sm text-gray-600 shadow-sm p-2 text-lg border-2 border-gray-300"
                                        />
                                        <div className='block w-full'> <Error name='organization' /></div>
                                    </div>
                                </div>
                                <div className='font-medium'>
                                    <Error name='category' />
                                    <FastField as="select"
                                        name="category"
                                        id="category"
                                        className="mt-1  border-2 border-gray-300 w-full rounded-md bg-white text-sm text-gray-600 shadow-sm p-2 text-lg "
                                    >
                                        <option className='text-gray-400'>Select Category</option>
                                        <option value="chat">Chat</option>
                                        <option value="code">code</option>
                                        <option value="text_to_image">Text to Image</option>
                                        <option value="image_to_text">Image to Text</option>
                                        <option value="text_to_speech">Text to Audio</option>
                                    </FastField>
                                </div>
                                <div className="font-medium text-sm text-[#8B8D98] text-ellipsis line-clamp-2">
                                    <Error name='description' />
                                    <FastField as="textarea"
                                        rows={3}
                                        id="description"
                                        name="description"
                                        placeholder="Description about model (max: 220 words)"
                                        maxLength={220}
                                        className="mt-1 w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='otherparams flex-1 xl:flex-[3] w-full p-3'>

                        {/* API url */}
                        <div className="w-full xl:max-w-[70%] ">
                            <label
                                htmlFor="routeUrl"
                                className="block text-sm font-medium text-gray-700 text-lg"
                            >
                                Link to Access: <Error name='routeUrl' />
                            </label>

                            <FastField
                                type="text"
                                id="routeUrl"
                                name="routeUrl"
                                placeholder="API Url i.e (https://model.decentr.ai/modelName)"
                                className="mt-1 w-full rounded-md  bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                            />

                        </div>

                        {/* Select input type */}
                        <FieldArray name='inputType'
                            render={arrayHelpers => (
                                <fieldset className='relative border-2 border-gray-300 p-4 w-full xl:max-w-[70%] rounded-lg'>
                                    <legend className='px-4 py-2'>  Input Types & Key:</legend>
                                    <Button
                                        design='secondary'
                                        type="button"
                                        style='inline-block absolute -top-[35px] right-[10px]'
                                        handleClick={() => arrayHelpers.push({ type: '', name: '' })}
                                    >
                                        +Add
                                    </Button>
                                    <div>


                                        {formik.values.inputType.map((input: any, index: any) => {

                                            return <div key={index} className="w-full ">
                                                <fieldset className=' border-2 border-gray-200 p-1 pb-2 w-full rounded-lg'>
                                                    <legend className='text-xs'>Input Type ({index + 1})</legend>
                                                    <div className="inline-block w-[35%] ml-[5%]">
                                                        <Error name={`inputType[${index}].type`} />
                                                        <Field as='select'
                                                            name={`inputType[${index}].type`}
                                                            id={`type${index}`}
                                                            className="mt-1 w-full rounded-md border-2 border-gray-300 bg-white text-sm text-gray-700 shadow-sm p-2 text-lg "
                                                        >
                                                            <option className='text-gray-400' value="">Select Input Type</option>
                                                            <option value="text">Text</option>
                                                            <option value="file">Image</option>
                                                        </Field>
                                                    </div>
                                                    <div className="inline-block w-[35%] ml-[5%]">
                                                        <Error name={`inputType[${index}].name`} />
                                                        <Field
                                                            type="text"
                                                            id={`name${index}`}
                                                            name={`inputType[${index}].name`}
                                                            placeholder=" Key "
                                                            className=" w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                                                        />
                                                    </div>
                                                    <Button style="inline-block w-[10%] ml-[5%]" type="button" handleClick={() => arrayHelpers.remove(index)} design={'primary'}>
                                                        delete
                                                    </Button>
                                                </fieldset>
                                            </div>



                                        })}

                                    </div>
                                </fieldset>
                            )}
                        />

                        <FieldArray
                            name="output"
                            render={arrayHelpers => (
                                <fieldset className='relative border-2 border-gray-300 p-4 w-full xl:max-w-[70%] rounded-lg'>
                                    <legend className='px-4 py-2'>  Output Types & Key:</legend>
                                    <Button
                                        design='secondary'
                                        type="button"
                                        style='inline-block absolute -top-[35px] right-[10px]'
                                        handleClick={() => arrayHelpers.push({ type: '', key: '' })}
                                    >
                                        +Add
                                    </Button>
                                    <div>


                                        {formik.values.output.map((input: any, index: any) => {

                                            return <div key={index} className="w-full ">
                                                <fieldset className=' border-2 border-gray-200 p-1 pb-2 w-full rounded-lg'>
                                                    <legend className='text-xs'>Output Type ({index + 1})</legend>
                                                    <div className="inline-block w-[35%] ml-[5%]">
                                                        <Error name={`output[${index}].type`} />
                                                        <Field as='select'
                                                            name={`output[${index}].type`}
                                                            id={`type${index}`}
                                                            className="mt-1 w-full rounded-md border-2 border-gray-300 bg-white text-sm text-gray-700 shadow-sm p-2 text-lg "
                                                        >
                                                            <option className='text-gray-400' value="">Select Input Type</option>
                                                            <option value="text">Text</option>
                                                            <option value="file">Image</option>
                                                        </Field>
                                                    </div>
                                                    <div className="inline-block w-[35%] ml-[5%]">
                                                        <Error name={`output[${index}].key`} />
                                                        <Field
                                                            type="text"
                                                            id={`key${index}`}
                                                            name={`output[${index}].key`}
                                                            placeholder=" Key "
                                                            className=" w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                                                        />
                                                    </div>
                                                    <Button style="inline-block w-[10%] ml-[5%]" type="button" handleClick={() => arrayHelpers.remove(index)} design={'primary'}>
                                                        delete
                                                    </Button>
                                                </fieldset>
                                            </div>
                                        })}

                                    </div>
                                </fieldset>
                            )}
                        />


                        <FieldArray
                            name='parameters'
                            render={arrayHelpers => (
                                <fieldset className='relative border-2 border-gray-300 p-4 w-full xl:max-w-[70%] rounded-lg'>
                                    <legend className='px-4 py-2'>  Parameters:</legend>
                                    <Button
                                        design='secondary'
                                        type="button"
                                        style='inline-block absolute -top-[35px] right-[10px]'
                                        handleClick={() => arrayHelpers.push({ name: ``, type: ``, min: ``, max: ``, current: ``, disable: 0 })}
                                    >
                                        +Add
                                    </Button>

                                    <div className="w-full">
                                        {formik.values.parameters.map((param, index) => {
                                            const { type } = param
                                            return <fieldset key={index} className=' border-2 border-gray-200 p-2 flex flex-col sm:flex-row flex-wrap items-center gap-2 sm:w-[100%] rounded-lg'>
                                                <legend className='px-4 py-2 text-xs'>  Parameter ( {index + 1}):</legend>
                                                <label className='w-[30%] flex flex-col text-xs' htmlFor={`parameters[${index}].type`}> Param Type
                                                    <Error name={`parameters[${index}].type`} />
                                                    <Field as='select'
                                                        name={`parameters[${index}].type`}
                                                        id={`parameters[${index}].type`}
                                                        className="  rounded-md border-2 border-gray-300 bg-white text-sm text-gray-700 shadow-sm p-2 text-lg "
                                                    >
                                                        <option className='text-gray-400' value="">Select Parameters Type</option>
                                                        <option value="text">Text</option>
                                                        <option value="range">Range</option>
                                                    </Field>
                                                </label>
                                                <div className=" sm:w-[25%]">
                                                    <label className='w-full flex flex-col text-xs' htmlFor={`parameters[${index}].name`}> Key
                                                        <Error name={`parameters[${index}].name`} />
                                                        <Field
                                                            type="text"
                                                            id="name"
                                                            name={`parameters[${index}.name]`}
                                                            placeholder=" key "
                                                            className=" w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                                                        />
                                                    </label>
                                                </div>
                                                <div className=" sm:w-[25%]">
                                                    <label className='w-full flex flex-col text-xs' htmlFor={`parameters[${index}].current`}> Default Value
                                                        <Error name={`parameters[${index}].current`} />
                                                        <Field
                                                            type="text"
                                                            id="current"
                                                            name={`parameters[${index}].current`}
                                                            placeholder=" default value"
                                                            className=" w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                                                        />
                                                    </label>
                                                </div>

                                                {type !== `range` ? `` : <><div className="sm:w-[20%]">
                                                    <label className='w-full flex flex-col text-xs' htmlFor={`parameters[${index}].min`}>Minimum
                                                        <Error name={`parameters[${index}].min`} />
                                                        <Field
                                                            type="text"
                                                            id="min"
                                                            name={`parameters[${index}].min`}
                                                            placeholder=" min "
                                                            className=" w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                                                        />
                                                    </label>
                                                </div>
                                                    <div className=" sm:w-[20%]">
                                                        <label className='w-full flex flex-col text-xs' htmlFor={`parameters[${index}].max`}>Maximum
                                                            <Error name={`parameters[${index}].max`} />
                                                            <Field
                                                                type="text"
                                                                id="max"
                                                                name={`parameters[${index}].max`}
                                                                placeholder=" max "
                                                                className=" w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                                                            />
                                                        </label>
                                                    </div>
                                                    <div className=" sm:w-[20%]">
                                                        <label className='w-full flex flex-col text-xs' htmlFor={`parameters[${index}].step`}>Per Step
                                                            <Error name={`parameters[${index}].step`} />
                                                            <Field
                                                                type="text"
                                                                id="step"
                                                                name={`parameters[${index}].step`}
                                                                placeholder=" step "
                                                                className=" w-full rounded-md bg-white text-sm text-gray-700 shadow-sm p-2 text-lg border-2 border-gray-300"
                                                            />
                                                        </label>
                                                    </div></>}
                                                <div className=" sm:w-[25%]">
                                                    <label className='w-full flex flex-col text-xs' htmlFor={`parameters[${index}].disable`}>Hidden From User
                                                        <Error name={`parameters[${index}].disable`} />
                                                        <Field as='select'
                                                            name={`parameters[${index}].disable`}
                                                            id="disable"
                                                            className="w-full  rounded-md border-2 border-gray-300 bg-white text-sm text-gray-700 shadow-sm p-2 text-lg "
                                                        >
                                                            <option value={1}>Yes</option>
                                                            <option value={0}>No</option>
                                                        </Field>
                                                    </label>
                                                </div>
                                                <div className=" sm:w-[25%]">
                                                    <Button
                                                        design={'primary'}
                                                        style='inline-block m-2 ml-3'
                                                        handleClick={() => arrayHelpers.remove(index)}
                                                    >delete</Button>
                                                </div>
                                            </fieldset>

                                        })}
                                    </div>
                                </fieldset>
                            )}

                        />






                        <div className='w-full xl:max-w-[70%] mt-4 flex flex-wrap items-center justify-center gap-4'>
                            <Button type='submit' design={'secondary'} >Submit Model</Button>
                            <Button type='button' design={'primary'}>Test Model</Button>
                        </div>
                    </div>


                </Form>
                }
            </Formik>


        </div>
    </>

}

export default AddCustomModel;


interface error {
    name: string,
}

export const Error: React.FC<error> = ({ name }) => {

    return <ErrorMessage name={name} component={`span`} className='text-xs text-red-400' />


}

// export const GenerateOptions: React.FC<any> = ({ options }) => {

//     if (!options) return null;

//     return options.map((option: any) => {
//         const { type, name, min, max, step = `1`, current = "", options, disable, replaceable } = option;
//         if (disable) {
//             return;
//         }
//         switch (type) {
//             case `range`:
//                 return (
//                     <span key={name}
//                         className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
//                         role="menuitem"
//                     >
//                         <label className="flex flex-col " htmlFor={name} hidden={disable}>
//                             {" "}
//                             {name}: {current}
//                             <input
//                                 title={current}
//                                 id={name}
//                                 name={name}
//                                 type={type}
//                                 min={min}
//                                 max={max}
//                                 step={step}
//                                 hidden={disable}
//                             />
//                         </label>
//                     </span>
//                 );

//             case `checkbox`:
//                 return (
//                     <span key={name}
//                         className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
//                         role="menuitem"
//                     >
//                         <label
//                             className="flex items-center gap-2 "
//                             htmlFor={name}
//                             hidden={disable}
//                         >
//                             {" "}
//                             {name}:
//                             <input
//                                 type={type}
//                                 name={name}
//                                 defaultChecked={!!current}
//                                 hidden={disable}
//                             />
//                             {`${current}`.toUpperCase()}
//                         </label>
//                     </span>
//                 );

//             case "text":
//                 return (
//                     <span key={name}
//                         className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
//                         role="menuitem"
//                     >
//                         <label className="flex flex-col " htmlFor={name} hidden={disable}>
//                             {" "}
//                             {name}:
//                             <input
//                                 type={type}
//                                 name={name}
//                                 className="bg-white border-[1px] border-gray-300 rounded-md text-sm p-1"
//                                 defaultValue={current}
//                                 hidden={disable}
//                             />
//                         </label>
//                     </span>
//                 );

//             case `dropdown`:
//                 return (
//                     <span key={name}
//                         className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
//                         role="menuitem"
//                     >
//                         <label className="flex flex-col " htmlFor={name}>
//                             {" "}
//                             {name}:
//                             <select
//                                 name={name}
//                                 value={current}
//                                 className="bg-white border-[1px] border-gray-300 rounded-md text-sm p-1"
//                                 hidden={disable}
//                             >
//                                 <option value="">-{name}-</option>
//                                 {options &&
//                                     options.length > 0 &&
//                                     options.map((option: any) => (
//                                         <option
//                                             className="p-2"
//                                             key={option.value}
//                                             value={option.value}
//                                         >
//                                             {option.label}
//                                         </option>
//                                     ))}
//                             </select>
//                         </label>
//                     </span>
//                 );

//             default:
//                 return <>type {type} not Defined</>;
//         }
//     });
// };









