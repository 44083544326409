import { useState } from "react";
import { useAppSelector } from "../../../Redux/hooks";
import { IoSettingsOutline, IoSettingsSharp } from "react-icons/io5";

const ModelInfoCard: React.FC<any> = ({ modelInfo,handleChange,modelPayload,setModelPayload }) => {

  const [isOptions,setIsOptions] = useState<Boolean>(false)
  const walletAddr = useAppSelector((state) => state.walletAddress);
  const { userInfo } = useAppSelector((state) => state.userProfile);


  const generateOptions = (options: any[]) => {
    return options.map((option: any) => {
      const { type, name, min, max, step = `1`, current = "", options, disable, replaceable } = option;
      if (!modelPayload.hasOwnProperty(name)) {

        if (replaceable) {
          const curnt = walletAddr ?? userInfo.email
          setModelPayload((prev: any) => ({ ...prev, [name]: curnt }));
        } else {
          if (type === `array`) {
            setModelPayload((prev: any) => ({ ...prev, [name]: `${current}`.split(`,`) }));
          } else {
            setModelPayload((prev: any) => ({ ...prev, [name]: current }));
          }
        }
      }
      if (disable) {
        return ;
      }
      switch (type) {
        case `range`:
          return (
            <span
              className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              role="menuitem"
            >
              <label className="flex flex-col " htmlFor={name} hidden={disable}>
                {" "}
                {name}: {modelPayload[name] ?? current}
                <input
                  title={modelPayload[name] ?? current}
                  id={name}
                  name={name}
                  type={type}
                  min={min}
                  max={max}
                  step={step}
                  value={modelPayload[name] ?? current}
                  onChange={handleChange}
                  hidden={disable}
                />
              </label>
            </span>
          );

        case `checkbox`:
          return (
            <span
              className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              role="menuitem"
            >
              <label
                className="flex items-center gap-2 "
                htmlFor={name}
                hidden={disable}
              >
                {" "}
                {name}:
                <input
                  type={type}
                  name={name}
                  checked={modelPayload[name]}
                  // value={inputOptionsData[name] ?? current}
                  onChange={handleChange}
                  hidden={disable}
                />
                {`${modelPayload[name] ?? current}`.toUpperCase()}
              </label>
            </span>
          );

        case "text":
          return (
            <span
              className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              role="menuitem"
            >
              <label className="flex flex-col " htmlFor={name} hidden={disable}>
                {" "}
                {name}:
                <input
                  type={type}
                  name={name}
                  className="bg-white border-[1px] border-gray-300 rounded-md text-sm p-1"
                  value={`${modelPayload[name] ?? current}`}
                  onChange={handleChange}
                  hidden={disable}
                />
              </label>
            </span>
          );

        case `dropdown`:
          return (
            <span
              className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
              role="menuitem"
            >
              <label className="flex flex-col " htmlFor={name}>
                {" "}
                {name}:
                <select
                  name={name}
                  onChange={handleChange}
                  value={modelPayload[name] ?? current}
                  className="bg-white border-[1px] border-gray-300 rounded-md text-sm p-1"
                  hidden={disable}
                >
                  <option value="">-{name}-</option>
                  {options &&
                    options.length > 0 &&
                    options.map((option: any) => (
                      <option
                        className="p-2"
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
          );

        default:
          return <>type {type} not Defined</>;
      }
    });
  };

  return <> <div className="flex flex-col md:flex-row gap-3 mb-2">
    <div>
      <img
        src={modelInfo?.logo ?? `/image/notFound.PNG`}
        className="max-w-[120px] max-h-[120px]  min-h-[100px] rounded-md shadow-md"
        alt=""
      />
    </div>
    <div className="flex flex-col items-start justify-start gap-3">
      <h4 className="font-extrabold text-xl sm:text-3xl md:text-4xl lg:text-4xl lg:text-bold ">{modelInfo?.title}</h4>
      <p>{modelInfo?.description}</p>
    </div>
  </div>

  {modelInfo &&
                modelInfo.parameters &&
                modelInfo.parameters.filter((param: any) => !param.disable).length > 0 && (
                  <>
                    {!isOptions ? (
                      <IoSettingsOutline
                        onClick={() => setIsOptions(!isOptions)}
                        className="absolute text-xl top-2 right-2 hover:rotate-45 -rotate-45 transition-all cursor-pointer"
                      />
                    ) : (
                      <IoSettingsSharp
                        onClick={() => setIsOptions(!isOptions)}
                        className="absolute text-xl top-2 right-2 hover:rotate-45 -rotate-45 transition-all cursor-pointer"
                      />
                    )}


                  </>
                )}


  <div onMouseLeave={() => setIsOptions(!isOptions)}
                      className={`
                ${!isOptions ? ` h-0 invisible ` : ` visible h-auto `}
                transition-all 
                absolute top-5 right-5 max-h-80 overflow-y-auto end-0 z-10 mt-2 w-56 rounded-md border border-gray-100 bg-white shadow-lg`}
                      role="menu"
                    >
                      <div className="p-2">
                        {modelInfo &&
                          modelInfo.parameters &&
                          generateOptions(modelInfo.parameters)}
                      </div>
                    </div>
  </>


}


export default ModelInfoCard;