import React from 'react'

enum enumDesign {
    Primary = "primary",
    Secondary = "secondary",
    Link = "link"
}


interface ButtonProps {
    children:any,
    design: enumDesign | string
    handleClick?:()=> any
    type?:`button` | `submit` | `reset`
    style?:string
}

const Button:React.FC<ButtonProps> = ({design,children,handleClick,type=`button`,style}) => {


    switch(design){
        case `primary`:
    return <button type={type} onClick={handleClick} className={` min-w-fit font-bold text-sm  bg-haiku-gradient py-2 px-4 rounded-3xl cursor-pointer bg-clip-text hover:bg-clip-border hover:text-white border-[1px] border-[#2BB2FF] text-transparent bg-[#ffffffd4] hover:shadow-button-shadow  ${style} transition-all duration-300 disabled:opacity-40`}>
    {children}
    </button> 

    case `secondary`:
        return  <button onClick={handleClick}  className={`shadow-primary min-w-fit font-bold text-sm bg-haiku-gradient  py-2 px-4 rounded-3xl cursor-pointer bg-clip-border text-white hover:shadow-button-shadow hover:bg-haiku-light-gradient ${style} transition-all duration-300  disabled:opacity-40`}>{children}</button>


    case `link`:
        return <button onClick={handleClick} className={`font-bold text-base text-[#2BB2FF] hover:border-b-[#2BB2FF] border-[1px] border-transparent cursor-pointer active:text-[#43abe7] ${style} `}>{children}</button>



    }

}


export default Button