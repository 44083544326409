import { createSlice } from "@reduxjs/toolkit";

export const addressSlice = createSlice({
    initialState: null,
    name: "WalletAddress",
    reducers: {
        updateAddress: (state, action) => action.payload,
    },
})

export const { updateAddress } = addressSlice.actions;
export default addressSlice.reducer;