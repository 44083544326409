import { useEffect, useState } from "react";
import { useAppSelector } from "../../Redux/hooks";
import {
  useWeb3ModalSigner,
  // useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import "./index.css";
import ToastMessage from "../../components/ToastMessage";
import subscriptionAbi from "../../constants/ABIs/subscriptionService/subscription.json";
import Modal from "../../components/model";
// import Modal from "../../components/Modal"
import WalletConnect from "../../services/WalletConnect";
import Button from "../../components/buttons";

function ClaimTokens() {
  const [isModal,setIsModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [fee, setFee] = useState<string>("");
  const [error, setError] = useState(false);
  const [toastMsg, setToastMsg] = useState("Tokens Sent Successfully");
  const walletAddress = useAppSelector((state) => state.walletAddress);
  const { signer } = useWeb3ModalSigner();
  const Haiku = "0x40801913d3445cFd7Eea86fF03469fE0aab0Bd73";
  const subscription = "0x818E7d66ffb09678eD32854A40b32039f50E3243";
  async function claimTokens(address: string | null) {
    try {
      const subscriptionContract = new ethers.Contract(
        subscription,
        subscriptionAbi,
        signer
      );
      const fee = await subscriptionContract.subscriptionFees(Haiku);
      setFee((Number(fee) / 10 ** 18).toString());
      const res = await subscriptionContract.claimToken();
      const receipt = await res.wait();
      if (receipt.status === 1) {
        setSuccess(true);
        setIsModal(true);
      } else {
        setError(true);
        setToastMsg("Error Sending Tokens");
        setShowToast(true);
      }
    } catch (error) {
      setError(true);
      setToastMsg("Error Sending Tokens");
      setShowToast(true);
    }
  }

  useEffect(()=>{
    walletAddress && setIsModal(false);
  },[walletAddress])

  return (<>
    <div className="w-full h-full min-h-screen flex flex-col justify-around items-center ClaimTokensBg rounded-xl">
      <div className="text-white font-bold lg:text-3xl md:text-3xl sm:text-xl">
        Get Your Free Tokens
      </div>
      <div className="w-3/4 h-1/4 bg-white flex flex-col gap-[10%] rounded-xl items-center justify-center">
        <input
          className="border-2 w-[70%] p-2 cursor-not-allowed rounded-md text-center my-6"
          style={{ maxWidth: "500px" }}
          value={walletAddress || ""}
          disabled
        />

        <div
          className="my-6 items-center "
          onClick={() =>  walletAddress ? claimTokens(walletAddress) : setIsModal(true)}
        >
          <Button design="secondary" handleClick={() =>  walletAddress ? claimTokens(walletAddress) : setIsModal(true)}>Claim Tokens</Button>
          {/* <span className="lg:text-lg md:text-md sm:text-sm font-bold">
            
          </span> */}
        </div>
      </div>
      <div />
      {/* {success && (
        <Modal
          title="Claim Token"
          btnRight="he"
          bodyColor="red"
          body={
            <>
              <h1 className="tex-3xl">{fee} Haiku tokens transfered</h1>
              <h4 className="text-sm text-blue-400 p-1.5">
                You can request for more Haiku in 3hours.
              </h4>
            </>
          }
          btnLeft="0"
          btnLeftAction={() => {
            // Add your action logic here if needed
          }}
        />
      ) } */}
        {showToast && <ToastMessage message={toastMsg} error={error} />}
    </div>
    <Modal isModal={isModal} closeModal={()=>{setIsModal(false);setSuccess(false)}}>
      <div className="w-full flex items-center justify-center">
      {!walletAddress && <WalletConnect />}
      {success &&
          <div>
          <h1 className="tex-3xl">{fee} Haiku tokens transfered</h1>
          <h4 className="text-sm text-blue-400 p-1.5">
            You can request for more Haiku in 3hours.
          </h4>
        </div>
      }
      </div>
    </Modal>
    </>
  );
}

export default ClaimTokens;
