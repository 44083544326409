import Image from "../Image";
import { useEffect, useState } from "react";

function ToastMessage(props: toastProps) {

  const hideTime = props.hideTime || 5000;
  const [show, setShow] = useState(true);
    
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, hideTime);
  }, []);

  return (
    <div>
      {show && (
        <div className={`inline-flex items-center justify-center rounded-lg z-50 ${props.error ? 'bg-red-700' : 'bg-[#333333]'} rounded-lg top-[4vh] absolute right-[2vw] p-2`}>
          <div className="p-1 bg-white rounded-lg animate-pulse">
            <Image src={`./icons/${props.error ? 'error' : `check`}.svg`} style={"w-8"} />
          </div>
          <div className="ml-3 text-white text-semi-bold p-2">
            {props.message}
          </div>
        </div>
      )}
    </div>
  );
}

export default ToastMessage;
