import React, { useState, useEffect } from "react";
import Image from "../../Image";
import { Link, useLocation } from "react-router-dom";
import { SidebarData, menuBarData
} from "./SidebarData";
// import ThemeSwitchers from "../../ThemeSwitchers";
// import Signin from "../../Signin";
import { IoMdClose } from "react-icons/io";

interface SidebarProps {
  isBelow1025:boolean,
  activeSidebar: boolean;
  handleSidebar: any

}

const Sidebar: React.FC<SidebarProps> = ({isBelow1025,activeSidebar,handleSidebar}) => {
  
  // const [isBelow1025,setIsBelow1025] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const [activeTab,setActiveTab] = useState(`/`)


  // const handleWidth = ()=>{
  //   if(window.innerWidth >= 1025){
  //     setIsBelow1025(false);
  //   }else{
  //     setIsBelow1025(true)
  //   }
  // }

  // useEffect(()=>{
  //   handleWidth()
  //   if(window){
  //     window.addEventListener(`resize`,handleWidth)
  //   }

  //   return ()=>{
  //     window.removeEventListener(`resize`,handleWidth)
  //   }
  // },[])

  useEffect(()=>{
    setActiveTab(pathname)
  },[pathname])

  return (<>
    <aside
      className={`${isBelow1025 ? ` fixed ` : ` sticky `} z-[9] top-0 w-[244px] py-6 pl-4 flex flex-col gap-6 text-black bg-white transition-all duration-[0.3s] ease-in-out overflow-y-auto border-r-[1px]   ${
        activeSidebar ? "w-auto min-w-[244px] min-h-screen h-fit max-h-screen " : "w-0 h-0"
      }`}
    >
      <div className="relative w-[212px] h-auto ml-3">
      {isBelow1025 && <IoMdClose onClick={handleSidebar} className="text-xl absolute right-1 hover:text-red-400 cursor-pointer" />}
      {!isBelow1025 && <Image src="/HaikuLogo.svg" style={`h-6 cursor-pointer`} />}
      </div>




      <ul className="w-[212px] ml-3 flex flex-col gap-1 justify-start min-h-fit ">
        {activeSidebar && menuBarData.map((item,index)=>{

          if(!item.route){
            return <h4 key={index} className={`${index > 0 && ` pt-5 `} py-1 font-bold text-xs`}>{item.name}</h4>
          }
          return  <li key={index} >
          <Link to={item?.disable ? ``:item.route} onClick={()=>{ if(isBelow1025){handleSidebar()}}}>
            <div className={`flex justify-start items-center h-10 px-1 py-2 gap-2 transition-all duration-[0.3s] hover:bg-[#2BB2FF1A] ${activeTab === item.route && ` bg-[#2bb1ff1A] ` } rounded-lg  group 
            ${item?.disable ? ` text-[#818181] cursor-not-allowed ` :` text-[#292929] cursor-pointer`}`} >
            <i className={`${activeTab === item.route ? ` text-[#2BB2FF] `: ` group-hover:text-[#2BB2FF] `} text-base`}>{item.icon}</i>
            <span className={`${activeTab === item.route ? ` text-[#2BB2FF] `: ` group-hover:text-[#2BB2FF] `} text-sm font-medium`}>{item.name}</span>
            </div>
          </Link>
        </li>

        })}
      </ul>
     
    </aside>
    {(activeSidebar && isBelow1025) && <div onClick={handleSidebar} className="fixed z-[8] top-0 left-0 w-full h-full bg-[#161616BF] transition-all duration-[0.3s]">
      
    </div>}
    </>
  );
}

export default Sidebar;
