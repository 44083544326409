import { useState, useEffect } from "react";
import { useAppSelector, 
  // useAppDispatch 
} from "../../Redux/hooks";
import {
  useWeb3ModalSigner,
  // useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import "./index.css";
import ToastMessage from "../../components/ToastMessage";
import Modal from "../../components/Modal/index";
// import ModalComponent from "../../components/ModalComponent";
import subscriptionAbi from "../../constants/ABIs/subscriptionService/subscription.json";
import haikuAbi from "../../constants/ABIs/HikuToken/token.json";
import Button from "../../components/buttons";
// import { subscribe } from "diagnostics_channel";
// import CheckToken from "../../utils/checkSubscription";

function SubscribeTokens() {
  const [showToast, setShowToast] = useState(false);
  const [address, setAddress] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [pacakage, setPacakage] = useState<string>("");
  const [pacakgeType, setPacakageType] = useState<number>(3);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [toastMsg, setToastMsg] = useState("Tokens Sent Successfully");
  // const walletAddress = useAppSelector((state) => state.walletAddress);
  const { signer } = useWeb3ModalSigner();
  // const dispatch = useAppDispatch();
  const { userSubscribed, userEndDate } = useAppSelector(
    (state) => state.userProfile
  );
  // const Haiku = "0x40801913d3445cFd7Eea86fF03469fE0aab0Bd73";
  const subscription = "0x818E7d66ffb09678eD32854A40b32039f50E3243";

  // useEffect(() => {
  //   if (walletAddress !== null) {
  //     CheckToken(walletAddress, signer, dispatch);
  //   }
  // }, [walletAddress]);

  const handleCryptoChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setToken(event.target.value);
  };

  const handlePacakge = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPacakage(event.target.value);
  };

  //set address of token from input
  useEffect(() => {
    switch (token) {
      case "HAIKU":
        setAddress("0x40801913d3445cFd7Eea86fF03469fE0aab0Bd73");
        break;
      case "USDT":
        setAddress("0x22f15736aB9A5b944B6ec41bAeE2782e0f1B7724");
        break;
      case "Select Token":
        setAddress("");
        break;
      default:
        setAddress("0x000000000000000000000000000000000000000");
        break;
    }
  }, [token]);

  useEffect(() => {
    switch (pacakage) {
      case "Daily":
        setPacakageType(0);
        break;
      case "Weekly":
        setPacakageType(1);
        break;
      case "Monthly":
        setPacakageType(2);
        break;
      default:
        setPacakageType(3);
        break;
    }
  }, [pacakage]);

  const getAddress = (): string => {
    return address;
  };

  async function subscribe() {
    try {
      const subscriptionContract = new ethers.Contract(
        subscription,
        subscriptionAbi,
        signer
      );
      let res, fee;
      const HaikuContract = new ethers.Contract(address, haikuAbi, signer);

      //switch-case to set fees according to pacakge selected
      switch (pacakage) {
        case "Daily":
          fee = await subscriptionContract.dailySubscriptionFees(address);
          break;
        case "Weekly":
          fee = await subscriptionContract.weeklySubscriptionFees(address);
          break;
        case "Monthly":
          fee = await subscriptionContract.subscriptionFees(address);
          break;
      }
      if (Number(fee) === Number(0)) {
        setError(true);
        setToastMsg("Token not supported.");
        setShowToast(true);
        return;
      } else {
        const approveTx = await HaikuContract.approve(subscription, fee);
        const approvalReceipt = await approveTx.wait();

        if (approvalReceipt.status === 1) {
          res = await subscriptionContract.subscribe(address, pacakgeType, {
            gasLimit: 500000,
          });
        }
        const receipt = await res.wait();
        if (receipt.status === 1) {
          setSuccess(true);
        } else {
          setError(true);
          setToastMsg("Error Subscribing");
          setShowToast(true);
        }
      }
    } catch (error) {
      setError(true);
      setToastMsg("Error Subscribing");
      setShowToast(true);
    }
  }

  return (
    <>
      {userSubscribed ? (
        <Modal
          title="Subscribe"
          btnRight="he"
          bodyColor="red"
          body={
            <>
              <h1>You are already subscribed</h1>
              <h4 className="text-xs text-blue-500 p-2">
                Your Subscription ends at {userEndDate}.
              </h4>
            </>
          }
          btnRightAction={() => {
            // Add your action logic here if needed
          }}
          btnLeft="0"
          btnLeftAction={() => {
            // Add your action logic here if needed
          }}
        />
      ) : (
        // ) : !userSubscribed && userTokenHolding ? (
        //   <Modal
        //     title="Subscribe"
        //     btnRight="he"
        //     bodyColor="red"
        //     body={
        //       <>
        //         <h1>You have enough tokens for instant subscription.</h1>
        //       </>
        //     }
        //     btnRightAction={() => {
        //       // Add your action logic here if needed
        //     }}
        //     btnLeft="0"
        //     btnLeftAction={() => {
        //       // Add your action logic here if needed
        //     }}
        //   />
        <div className="w-full h-full min-h-screen flex flex-col justify-center gap-10 items-center ClaimTokensBg rounded-xl">
          <div className="text-white font-bold lg:text-3xl md:text-3xl sm:text-xl">
            Subscribe to Access Models
          </div>
          <div className="w-3/4 h-2/4 bg-white flex flex-col gap-[10%] rounded-xl items-center justify-center">
            <div className="w-3/4 h-1/4 bg-white flex-row gap-[1%] rounded-xl items-center">
              {pacakgeType === 3 ? (
                <div className="relative lg:max-w-m items-center mt-6">
                  <select
                    value={pacakage || ""}
                    onChange={handlePacakge}
                    className="w-full p-2.5 text-black-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600 "
                  >
                    <option>Select Subscription Pacakage</option>
                    <option>Daily</option>
                    <option>Weekly</option>
                    <option>Monthly</option>
                  </select>
                </div>
              ) : (
                <div className="relative lg:max-w-m items-center">
                  <select
                    value={token || ""}
                    onChange={handleCryptoChange}
                    className="w-full p-2.5 text-black-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600 "
                  >
                    <option>Select Token</option>
                    <option>HAIKU</option>
                    <option>USDT</option>
                  </select>
                </div>
              )}
            </div>
            {token && pacakage && address !== "" && (
              <>
                <div id="addressDisplay">Package: {pacakage} Subscription</div>
                <div id="addressDisplay">Address: {getAddress()}</div>
              </>
            )}

            <div
              className="items-center  drop-shadow-3xl my-8"
              // onClick={() => subscribe()}
            >
            <Button design="secondary" handleClick={() => subscribe()} style=" py-3 "> Subscribe With Tokens</Button>

              {/* <span className="lg:text-lg md:text-md sm:text-sm font-bold">
                Subscribe With Tokens
              </span> */}
            </div>
          </div>
          {success ? (
            <Modal
              title="Subscribe"
              btnRight="he"
              bodyColor="red"
              body={<h1>Subscribed Successfully!</h1>}
              btnRightAction={() => {
                // Add your action logic here if needed
              }}
              btnLeft="0"
              btnLeftAction={() => {
                // Add your action logic here if needed
              }}
            />
          ) : (
            showToast && <ToastMessage message={toastMsg} error={error} />
          )}
        </div>
      )}
    </>
  );
}

export default SubscribeTokens;
