import { createSlice } from "@reduxjs/toolkit";

interface UserProfileState {
  userInfo: {
    name: string;
    email: string;
  };
  singinType: string;
  userSubscribed: boolean;
  userTokenHolding: boolean;
  userEndDate: string;
}

function getUserProfile(){

  const userData = sessionStorage.getItem(`decentrai`);
  if(userData){
      return JSON.parse(userData);
  }
  sessionStorage.removeItem(`decentrai`)
  return {
    userInfo: {
      name: "",
      email: "",
    },
    singinType: "",
    userSubscribed: false,
    userTokenHolding: false,
    userEndDate: "",
  }

}


function setProfileData(profileData:any){

  if(profileData){
    sessionStorage.setItem(`decentrai`,JSON.stringify(profileData))
  }
}

const initialState: UserProfileState = getUserProfile()

const userProfile = createSlice({
  name: "UserProfile",
  initialState,
  reducers: {
    setUserProfile: (state, action) => {
      state.userInfo = action.payload;
      setProfileData(state);
    },
    setSigninType: (state, action) => {
      state.singinType = action.payload;
      setProfileData(state);
    },
    setUserSubscribed: (state, action) => {
      state.userSubscribed = action.payload;
      setProfileData(state);
    },
    setUserEndDate: (state, action) => {
      state.userEndDate = action.payload;
      setProfileData(state);
    },
    setUserTokenHolding: (state, action) => {
      state.userTokenHolding = action.payload;
      setProfileData(state);
    },
  },
});

export const {
  setUserProfile,
  setSigninType,
  setUserSubscribed,
  setUserEndDate,
  setUserTokenHolding,
} = userProfile.actions;

export default userProfile.reducer;
