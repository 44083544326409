function Image(props: any) {
  return (
    <img
      className={`${props.style}`}
      src={process.env.PUBLIC_URL + props.src}
      alt={props.alt}
    />
  );
}

export default Image;
