import { configureStore } from "@reduxjs/toolkit";
import walletAddress from "./Slices/walletAddress";
import maticBalance from "./Slices/maticBalance";
import theme from "./Slices/theme";
import userProfile from "./Slices/userProfile";
import models from "./Slices/models";

export const store = configureStore({
  reducer: {
    walletAddress: walletAddress,
    maticBalance: maticBalance,
    theme: theme,
    userProfile: userProfile,
    models:models,

  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
