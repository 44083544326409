const TypeAnimation = () => {
  return (
    <div className="loader pb-1">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default TypeAnimation;
