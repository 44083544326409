import {
  // BrowserRouter,
  Route,
  Routes,
  // HashRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom";
import DashboardLayout from "./components/_App/DashboardLayout";
import NoPage from "./components/_App/NoPage";
import ClaimTokens from "./pages/ClaimTokens";
import SubscribeTokens from "./pages/subscribeWithTokens";
import Chatbot from "./pages/chatbot";
import { useEffect, useLayoutEffect, useState } from "react";
import { useAppSelector } from "./Redux/hooks";
import CheckToken from "./utils/checkSubscription";
import { useWeb3ModalSigner } from "@web3modal/ethers5/react";
import { useDispatch } from "react-redux";
import Modal from "./components/model";
import LoginModalButtons from "./components/loginModalButtons";
import NewModel from "./pages/newModel";
import Models from "./pages/models";
import Home from "./pages/Home";
import AllModels from "./pages/AllModels";
import CustomModel from "./pages/customModel";
import SearchEngine from "./pages/searchEngine";

function App() {
  const [isLoginRequired, setIsLoginRequired] = useState(false);
  const [subscriptionRequired, setSubscriptionRequired] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const { signer } = useWeb3ModalSigner();
  const walletAddr = useAppSelector((state) => state.walletAddress);
  const { userInfo, userSubscribed, userTokenHolding } = useAppSelector(
    (state) => state.userProfile
  );
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    walletAddr && setIsLoginRequired(false);
    walletAddr && CheckToken(walletAddr, signer, dispatch);
  }, [walletAddr]);

  useEffect(() => {
    isLoginRequired && !isModal && navigate(`/`);
  }, [isModal]);

  useLayoutEffect(() => {
    if (pathname !== `/`) {
      //check for user login on different routes
      if ([`/chatbot`, `/Subscribe`, `/ClaimTokens`].includes(pathname)) {
        if (walletAddr === null && userInfo.email === ``) {
          setIsLoginRequired(true);
          setIsModal(true);
        } else if (walletAddr && !userSubscribed && !userTokenHolding) {
          setIsLoginRequired(false);
          setSubscriptionRequired(true);
          //  subscription required
          setIsModal(true);
        } else {
          setSubscriptionRequired(false);
          setIsLoginRequired(false);
        }
      }

      if ([`/comingSoon`].includes(pathname)){
        setIsModal(true)

      }
    }
  }, [pathname, userInfo.email, walletAddr]);

  return (
    <>
      <Routes>
        <Route path="/" element={<DashboardLayout />}>
          {/* <Route index element={<Marketplace />} /> */}
          <Route index element={<Home />} />
          <Route path="/AllModels" element={<AllModels />} />

          <Route path="/newModel" element={<CustomModel />} />
          <Route path="/models" element={<Models />} />
          <Route path="/SearchEngine" element={<SearchEngine/>} />
          {!isLoginRequired ? (
            <>
              <Route path="/ClaimTokens" element={<ClaimTokens />} />
              <Route path="/Subscribe" element={<SubscribeTokens />} />
              <Route path="/chatbot" 
                     element={
                      !subscriptionRequired ?
                      <Chatbot />
                      : <Modal
                      isModal={isModal}
                      closeModal={() => {
                        navigate(`/`);
                        setIsModal(false);
                      }}
                    >
                      {" "}
                      <>Subscription required</>
                    </Modal>} />
            </>
          ) : (
            <>
              <Route
                path="*"
                element={
                  <Modal
                    isModal={isModal}
                    closeModal={() => {
                      setIsModal(false);
                    }}
                  >
                    <LoginModalButtons />{" "}
                  </Modal>
                }
              />
            </>
          )}
        </Route>
        
        <Route path="/comingSoon" element={<Modal
                      isModal={isModal}
                      closeModal={() => {
                        navigate(`/`);
                        setIsModal(false);
                      }}
                    >
                      {" "}
                      <>Coming Soon</>
                    </Modal>} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </>
  );
}

export default App;
