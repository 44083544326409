import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import Modal from "../components/model";
import { AddTogetheraiModels } from '../components/forms/addTogetheraiModels';


const Models = () => {

    const [isModal,setIsModal] = useState(false);

    return (<>
        <section className="bg-gray-50 w-full">
  <div className="mx-auto max-w-screen-xl lg:flex lg:h-screen lg:items-center">
    <div className="mx-auto max-w-2xl text-center">
      <h1 className="text-3xl font-extrabold sm:text-5xl">
      Welcome to DecentrAI, 
        
        <strong className="font-extrabold text-blue-700 sm:block "> Where Innovation Knows No Boundaries </strong>
      </h1>
  

      <p className="mt-4 sm:text-xl/relaxed ">
      Empower your platform with diverse AI capabilities by seamlessly integrating your own AI models with Decentrai.
      </p>

      <div className="mt-8 flex flex-wrap justify-center gap-4">
      <p onClick={()=>setIsModal(true)}
          className="cursor-pointer block w-full rounded bg-blue-600 px-12 py-3 text-sm font-medium text-white shadow hover:bg-blue-700 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
         
        >
          Add TogetherAi Models
        </p>

        <Link to={`/newModel`} 
          className="block w-full rounded px-12 py-3 text-sm font-medium text-blue-600 shadow hover:text-blue-700 focus:outline-none focus:ring active:text-blue-500 sm:w-auto cursor-pointer"
        >
          Add Custom Model
          </Link>
      </div>
    </div>
  </div>
</section>
<Modal isModal={isModal} closeModal={() => {setIsModal(false)}}>
    <AddTogetheraiModels/>
  </Modal> 
</>
    )

}

export default Models;