import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './Redux/Store';
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google';
import {
  Route,
  Routes,
  HashRouter as Router
} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <GoogleOAuthProvider clientId="761766916835-qs86aju4dvmti4io4ktjg3jm6dn2igfl.apps.googleusercontent.com">
        <Router>
          <Routes>
            <Route path='*' element={<App />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
