// import { useAppSelector } from "../../Redux/hooks";
import GoogleSignIn from "../../services/Google-SignIn";
import WalletConnect from "../../services/WalletConnect";
import Web3Auth from "../../services/Web3Auth";

const Index = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center h-full gap-3 py-10">
      <GoogleSignIn />
      <WalletConnect />
      <Web3Auth />
    </div>
  );
};

export default Index;
