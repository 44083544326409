import { IoMdArrowRoundForward } from "react-icons/io";
import { MdAttachFile } from "react-icons/md";

const ModelInputs:React.FC<any> = ({modelInfo,modelPayload,handleChange}) => {


    return <>
    {modelInfo  ? modelInfo?.inputType?.map((input: any) => {
      const { type, name, isRequired, options } = input;

      switch (type) {
        case "text":
          return (
            <input
              type={type}
              name={name}
              placeholder="Talk to Assistant"
              className="z-[2] border-[2px] border-gray-500 w-full px-6 py-3  rounded-2xl sm:rounded-full bg-white text-gray-900 "
              style={{
                boxShadow: `rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px`,
              }}
              required={isRequired}
              value={
                modelPayload.hasOwnProperty(name) ? modelPayload[name] : ``
              }
              onChange={handleChange}
            />
          );

        case "file":
          return (
            <div
              className="right-5 relative h-full  z-[3] "
              style={{ position: `absolute` }}
            >
              {modelPayload[name] && modelPayload[name].length > 0 && (
                <div
                  className="bg-green-200 rounded-sm h-[50px] absolute z-[-1] top-[-40px] transition-y-[50%] right-[4rem] transition-x-[-10%] "
                  style={{
                    clipPath: `polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%)`,
                  }}
                >
                  <span className=" mt-2 p-2 text-md font-thin text-green-700 ">
                    Uploaded
                  </span>
                </div>
              )}
              <input
                type={type}
                name={name}
                accept="image/*"
                className="w-5 opacity-0 border-4 border-gray-400 absolute top-[20%] transition-y-[50%] right-[5rem] transition-x-[-10%]  rounded-xl "
                style={{
                  boxShadow: `rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px`,
                }}
                required={isRequired}
                onChange={handleChange}
              />
              <MdAttachFile className="z-[-1] w-2 absolute scale-[4] top-[40%] transition-y-[-50%] right-[5rem] transition-x-[-10%] " />
            </div>
          );

        case "dropdown":
          return (
            <select
              title={`Select ${name}`}
              name={name}
              onChange={handleChange}
              value={
                modelPayload.hasOwnProperty(name) ? modelPayload[name] : ``
              }
              className="w-40 border-[1px] border-gray-400 absolute top-[50%] translate-y-[-50%] right-14  py-2 rounded-lg bg-gray-50 text-gray-900 "
              required={isRequired}
            >
              <option value="">-{name}-</option>
              {options &&
                options.length > 0 &&
                options.map((option: any) => (
                  <option
                    className="p-2"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
            </select>
          );

        default:
          return <>type {type} not Defined</>;
      }
    }): <></> }
      <button
                className="absolute z-[11] top-[50%] translate-y-[-50%]  right-3 flex items-center ml-auto bg-black p-2 rounded-full hover:scale-[0.87] transition cursor-pointer"
                type="submit"
              >
                <IoMdArrowRoundForward className="text-lg text-white transition-all" />
              </button>
      
    
    </>

}


export default ModelInputs;