import { ChangeEvent, FormEvent, useEffect, useState } from "react";

import { getRequest, postRequest } from "../api/axios";
import ChatbotResponse from "../components/chatbot-sections/ChatbotResponse";
import { uploadFileToIPFS } from "../components/chatbot-sections/functions";
import ModelInfoCard from "../components/chatbot-sections/modelInfoCard";
import TemporaryMsgs from "../components/chatbot-sections/temporaryMessages";
// import { useAppSelector } from "../Redux/hooks";
import { useSelector } from "react-redux";
import Search from "../components/modelSearch";
import ModelInputs from "../components/chatbot-sections/modelInputs";

interface Response {
  user: string;
  server?: string;
}
const Chatbot: React.FC<any> = () => {
  const queryParameters = new URLSearchParams(
    window.location.hash.split("?")[1]
  );
  const modelName = queryParameters.get("model");
  const modelsList = useSelector((state: any) => state.models)
  const [modelInfo, setModelInfo] = useState<any>(null);


  // const walletAddr = useAppSelector((state) => state.walletAddress);
  // const { userInfo } = useAppSelector((state) => state.userProfile);

  // const [isSidebar, setIsSidebar] = useState(false);
  // const [isOptions, setIsOptions] = useState(false);
  // const [messageSplitter, setMessageSplitter] = useState(`|*|*|*|*|*|*|*|*|*|`)

  const [modelPayload, setModelPayload] = useState<any>({});
  const [responseHistory, setResponseHistory] = useState<Response[] | null>(
    null
  );

  const [showTempMsg, setShowTemMsg] = useState<boolean>(false);
  const [tempMsg, setTempMsg] = useState<any>(null);

  const handleChange = async (e: ChangeEvent<any>) => {
    if (e.target.type === `file`) {
      const file = e.target.files[0];
      if (modelInfo?.imageType === "base64") {
        const reader = new FileReader();
        reader.onload = () => {
          const result = reader.result;
          setModelPayload((prev: any) => ({
            ...prev,
            [e.target.name]: result,
          }));
        };
        reader.readAsDataURL(file);


      } else {
        const ipfsHash = await uploadFileToIPFS(file);
        if (ipfsHash) {
          setModelPayload((prev: any) => ({
            ...prev,
            [e.target.name]: `https://ipfs.io/ipfs/${ipfsHash}`,
          }));
        }
      }
    } else if(e.target.type === "checkbox") {
      setModelPayload((prev: any) => ({
        ...prev,
        [e.target.name]: e.target.checked,
      }));
    }else{
      setModelPayload((prev: any) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  useEffect(() => {
    const getModelData = async () => {
      const model = modelsList.filter((model: any) => model.modelName === modelName);
      if (model.length > 0) {
        setModelInfo(model[0]);

      } else {
        await getRequest(`/api/availableModels/${modelName}`).then((data) => {
          setModelInfo(data.data);
        });
      }
    };
    modelName && modelName.length > 0 && getModelData();
  }, [modelName,modelsList]);

  const setChatState = (state: string) => {
    if (state === "pending") {
      setShowTemMsg(true);
      let inputPayload = {};
      modelInfo?.inputType.forEach((input: any) => {
 
       inputPayload = {...inputPayload, [input.name]:  modelPayload[input.name] }
 
      })
      let tempMsg = inputPayload
      setTempMsg((prev: any) => tempMsg);
      document.getElementById(`end`)?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handlePrompt = async () => {
    setChatState("pending");

    let payload: any = { ...modelPayload };
    setModelPayload({});

    if (modelInfo?.createdBy === "togetherai") {

      let previousHistory: any[] | null = responseHistory && responseHistory?.slice(-5);
      let userKey = modelInfo?.inputType[0].name;
      let assistantKey = modelInfo?.output[0].key;
      let historyPayload: any[] = [];
      previousHistory && previousHistory.forEach((chat, index) => {
        historyPayload.push({
          role: "user",
          content: chat?.user[userKey] ?? ""
        }, {
          role: "assistant",
          content: chat?.server[assistantKey] ?? ""
        })
      })

      if (historyPayload && historyPayload.length > 0) {
        payload.chatHistory = historyPayload;
      }
    }
    let data = await postRequest(
      `/api/availableModels/${modelInfo.modelName}`,
      payload
    );

    if (modelInfo?.createdBy === "togetherai") {

      delete payload.chatHistory;

    }

    let serverResponse: any = {};

    let inputPayload = {};
     modelInfo?.inputType.forEach((input: any) => {

      inputPayload = {...inputPayload, [input.name]:  modelPayload[input.name] }

     })
    serverResponse.user = inputPayload
    if (data) {
      serverResponse.server = data;
    } else {
      serverResponse.server = `Server Error`;
    }

    document.getElementById(`end`)?.scrollIntoView({ behavior: "smooth" });
    setShowTemMsg(false);
    setResponseHistory((prevResponses) =>
      prevResponses ? [...prevResponses, serverResponse] : [serverResponse]
    );
  };

  useEffect(()=>{

    console.log(`responseHistory`,responseHistory)

  },[responseHistory,responseHistory?.length])

  return (
    <>
      <div
        className={`min-w-[270px] w-full min-h-auto grid grid-cols-8 h-[92vh] transition-all`}
      >
        <div className="col-span-8 flex flex-col items-center justify-end border-r-2 pr-3 border-gray-300 relative">
          <div className="chatbox w-full h-full min-h-[79vh] max-h-[80vh]  flex flex-col gap-1 overflow-auto bg-gray-100 rounded-xl p-2 ">
            <div className="relative z-[9] w-[95%] ml-auto mr-auto mt-2 bg-violet-100 p-3 rounded-xl border-2 border-violet-200">
              <ModelInfoCard modelInfo={modelInfo} modelPayload={modelPayload} setModelPayload={setModelPayload} handleChange={handleChange} />
              <div className="w-full flex justify-end align-middle"><span><Search /></span></div>
            </div>

            {responseHistory &&
              responseHistory.length &&
              responseHistory.map((res, index) => {
                return <ChatbotResponse key={index} response={res} modelInfo={modelInfo} />;
              })}

            {showTempMsg && <TemporaryMsgs modelInfo={modelInfo} tempMsg={tempMsg} />}

            <div className="min-h-[80px] w-full "></div>
            <div id="end"></div>
          </div>

          <div className="flex items-center w-[93%] ml-auto relative mt-2">
            <form
              className="w-full relative min-h-[3rem] flex item-center justify-center"
              onSubmit={(e: FormEvent) => {
                e.preventDefault();
                handlePrompt();
              }}
            >
              <ModelInputs modelInfo={modelInfo} modelPayload={modelPayload} handleChange={handleChange} />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chatbot;
