import { ChangeEvent, useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Image from "../Image"



const Search:React.FC<any> = ({mobile}) => {

    const [search,setSearch] = useState(``);
    const [modelList,setModelList] = useState([]);

    const models = useSelector((state:any) => state.models)

    useEffect(()=>{

      const filteredModels = models.filter((model:any) => (model.title.toLowerCase().includes(search.toLowerCase()) || model.organization.toLowerCase().includes(search.toLowerCase())))
      setModelList((prev)=>filteredModels)
      
    },[search])

    const handleChange = (e:ChangeEvent<HTMLInputElement>) => {
        setSearch((prev)=>`${e.target.value}`)
    }


  return <> <div className={`relative`}>
    <label className={`relative w-full h-full overflow-hidden rounded-md border border-gray-200 focus-within:border-[#2BB2FF] focus-within:ring-1 focus-within:ring-[#2BB2FF] md:min-w-[340px] ${!mobile ? `hidden md:flex ` : ` flex md:hidden `}  `}>
    <IoSearch className='absolute left-2 top-2 z-[1]' />
      <input type='text' onChange={handleChange} value={search}
      className=' w-full border-none p-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm rounded-sm pl-8  h-full py-2 px-3  ' 
      placeholder='Search models by title or oganization name' />
      </label>
      {search.length > 0 &&<div className={`shadow-model-shadow flex-col bg-white rounded-b-xl absolute top-[100%] w-full min-h-[100px] max-h-[300px] overflow-y-auto  ${!mobile ? `hidden md:flex ` : ` flex md:hidden `} transition-all duration-300 `}>
        {modelList.length > 0 ? modelList.map((model:any) => {
          return <Link onClick={()=>setSearch(``)} to={`/chatbot?model=${model.modelName}`} className='font-bold text-md flex gap-2 cursor-pointer my-1 p-2 group'>
            <Image src={model.logo} style={`w-10 h-10 rounded-md cursor-pointer`} alt={model.title}/>
            <div className='w-full'>
            <h6 className="font-bold text-md group-hover:text-[#2BB2FF]">{model.title}</h6>
            <p className="font-medium text-xs text-[#8B8D98]">by {model.organization}</p>
            </div>
            </Link>
        })
        :
        <div className=' flex items-center justify-center h-[100px] '>
            <h6 className="font-bold text-md ">No Model Available</h6>
            </div>
      
      }
      </div>}
      </div>
      </>

}


export default Search