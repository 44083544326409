import { ethers } from "ethers";
import subscriptionAbi from "../constants/ABIs/subscriptionService/subscription.json";
import { setUserSubscribed, setUserEndDate, setUserTokenHolding } from "../Redux/Slices/userProfile";

function getFormattedDate(timestamp: number): string {
    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds

    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day} ${monthNames[monthIndex]} ${year}`;
}


const CheckToken = async (walletAddr: string, signer: any, dispatch:any) => {
  const subscription = "0x818E7d66ffb09678eD32854A40b32039f50E3243";
  try {
    const subscriptionContract = new ethers.Contract(
      subscription,
      subscriptionAbi,
      signer
    );
    const holdingAllowance = await subscriptionContract.hasAccessBasedOnTokens(walletAddr);
    console.log("Allowance",holdingAllowance);
    const allowance = await subscriptionContract.isSubscribed(walletAddr);
    const subDate = await subscriptionContract.getSubscriptionDate(walletAddr);
    // console.log(`holding allowance:`,holdingAllowance)
    // console.log(` allowance:`,allowance)
    // // console.log(` allowance:`,allowance)
    
// Format the date as a string
const formattedDate = getFormattedDate(subDate);
    if (allowance && !holdingAllowance) {
      dispatch(setUserSubscribed(true));
      dispatch(setUserEndDate(formattedDate));
      dispatch(setUserTokenHolding(false));
    } else if(!allowance && holdingAllowance){
      dispatch(setUserSubscribed(false));
      dispatch(setUserEndDate(formattedDate));
      dispatch(setUserTokenHolding(true));
    } 
    else if(allowance && holdingAllowance) {
      dispatch(setUserSubscribed(true));
      dispatch(setUserEndDate(formattedDate));
      dispatch(setUserTokenHolding(true));
    }
    else{
       dispatch(setUserSubscribed(false));
      dispatch(setUserEndDate(""));
      dispatch(setUserTokenHolding(false));
    }
  } catch (error) {
    console.log("CheckToken_Error", error);
  }
};
export default CheckToken;
