import React, { useEffect, useState } from 'react';
import { FaRegCircleCheck } from "react-icons/fa6";
import Button from '../../components/buttons';
import { useAppSelector } from '../../Redux/hooks';
import Card from '../../components/cards/modelCard';
import CardSkelton from '../../components/cards/modelCardSkelton';
import { useNavigate } from 'react-router-dom';
import HeroSection from '../../components/heroSections/main';


const Home = () => {

    const modelsList = useAppSelector((state) => state.models)

    const [featuredModels, setFeaturedModels] = useState([]);
    const [skeltonCount,setSkeltonCount] = useState(4)
    const navigate = useNavigate()


    useEffect(() => {

        if (modelsList) {
            setFeaturedModels(modelsList.filter((model: any) => model?.isFeatured));
        }

    }, [modelsList.length]);


    return <>
        <div className='w-full' >
            <HeroSection/>
            <div className="max-w-[1320px] ml-auto mr-auto">
                <div className="flex justify-between items-center px-10 mb-3">
                    <h4 className="font-bold text-lg">Featured models</h4>
                    <Button design={'link'}>All Categories</Button>
                    {/* <p className="font-bold text-base text-[#2BB2FF]">All Categories</p> */}
                </div>
                <div className="flex flex-wrap items-start justify-beetween gap-6 px-8">
                    {featuredModels.length > 0 && featuredModels.map((model, index) => {
                        return <Card
                            key={index}
                            model={model}
                        />
                    })}
                    {featuredModels?.length <= 0 && [...Array(skeltonCount)].map((key, index) => {
                        return <CardSkelton key={index} />
                    })}
                </div>
                <div className='flex justify-center items-center mt-10'>
                    <Button design={'primary'} style={` mx-auto px-4 py-3`} handleClick={()=>navigate(`/AllModels#models`)}>See all models</Button>
                </div>
                <div className='flex flex-wrap justify-center items-center  min-h-[352px] my-20 gap-6 p-4'>
                    <div className='flex flex-1 flex-col text-4xl font-bold shadow-card-shadow rounded-xl px-10 py-16 bg-box-pattern bg-cover bg-right bg-no-repeat'>
                        <p className='bg-haiku-gradient bg-clip-text text-transparent'>Simple & Flexible</p>
                        <p>fiat or crypto pricing</p>
                        <Button design={`primary`} style='mt-10 max-w-fit'>See pricing</Button>
                    </div>
                    <div className='flex flex-1 flex-col text-4xl font-bold shadow-card-shadow rounded-xl px-10 py-16 bg-box-pattern bg-cover bg-right bg-no-repeat'>
                        <p className='bg-haiku-gradient bg-clip-text text-transparent'>Fast & Easy</p>
                        <p>Installation for Devs</p>
                        <Button design={`primary`} style=' mt-10 max-w-fit'>See Documentation</Button>
                    </div>
                </div>
            </div>
        </div>
    </>

}

export default Home;


