import { createSlice } from "@reduxjs/toolkit";

export const themeSlice = createSlice({
    initialState: 'light',
    name: "theme",
    reducers: {
        updateTheme: (state, action) => action.payload,
    },
})

export const { updateTheme } = themeSlice.actions;
export default themeSlice.reducer;