import AddCustomModels from "../components/forms/addCustomModels";
import AddCustomModel from "../components/forms/addCustomModel";


const CustomModel = () =>{

    // return <AddCustomModels />
    return <AddCustomModel />

}

export default CustomModel;