import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import { setSigninType, setUserProfile } from "../../Redux/Slices/userProfile";
import { postRequest } from "../../api/axios";
import { FaGoogle } from "react-icons/fa";

// interface Profile {
//   name: string;
//   email: string;
// }

function GoogleSignIn() {
  // const [profile, setProfile] = useState<Profile | null>(null);
  const { userInfo } = useAppSelector((state) => state.userProfile);
  const dispatch = useAppDispatch();

  const handleLogin = async (googleData: any) => {
    try {
      const res = await postRequest(`/api/addUser`, { token: googleData.code });
      const data = res;
      console.log(`Login Response`, data);
      dispatch(setUserProfile({ name: data.name, email: data.email }));
      dispatch(setSigninType("google"));
    } catch (err) {
      console.error(`/api/addUser`, err);
    }
  };

  //login with google via useGoogleLogin sdk
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => handleLogin(codeResponse),
    onError: (error: any) => console.log("Login Failed : ", error),
    flow: "auth-code",
  });

  const logOut = () => {
    googleLogout();
  };

  return (
    <div>
      {userInfo.email !== `` ? (
        <div>
          {/* <p>Name: {profile.name}</p> */}
          <button
            onClick={logOut}
            className="mx-2 mb-2 p-1 border-2 border-gray-200 rounded-md"
          >
            Log out
          </button>
        </div>
      ) : (
        <button
          onClick={() => login()}
          className="flex items-center text-gray-600 text-lg justify-center gap-2 mx-2 mb-2 p-1 py-2 border-2 border-gray-200 rounded-md"
        >
          <FaGoogle /> Sign in with Google{" "}
        </button>
      )}
    </div>
  );
}

export default GoogleSignIn;
